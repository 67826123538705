import { createRouter, createWebHistory } from 'vue-router';
import HomeDP from '@/views/homeDP/index.vue'; // 引入一个示例视图组件
import webSocketPage from '@/views/homeDP/webSocketPage.vue';
const routes = [
  {
    path: '/',
    name: 'homeDP',
    component: HomeDP,
  },
  {
    path: '/webSocketPage',
    name: 'webSocketPage',
    component: webSocketPage
  }
  // 其他路由配置
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;