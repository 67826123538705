<template>
	<div class="dp" :style="{ 'backgroundColor': data.basics.backGroundColor||'rgb(0,0,0)' }">
		<div v-if="difference2 == '过期'" class="dqtx">
			<div>
				<p>您的软件已经过期{{ timeDifference ? timeDifference : "超过一周啦" }}</p>
				<p>请及时联系您的业务员或拨打电话进行续费</p>
				<p>
					服务热线：
					<a href="tel:0755-82222526">0755-82222526</a>
				</p>
			</div>
		</div>
		<!-- 水印 -->
		<WaterMarks v-if="data.basics.waterMarkFlag"/>
		<FloatingWindow v-if="timeDifference" width="300px" height="85px">
			<p>中威软件温馨提示：</p>
			<p style="text-align: left">
				您的软件将在
				<a style="color: red">{{ timeDifference }}</a>
				后到期，请及时续费,以免影响您的正常使用！
			</p>
		</FloatingWindow>
		<notPage v-if="data.notPages" />
		<div class="dp-title" :style="{ 'backgroundColor': adjustGrayscaleRgb(data.basics.backGroundColor||'rgb(0,0,0)') }">
			<img style="height: 100%; width: auto;" :src="data.basics.logoUrl" />
			<div style="width:100%">
				<div style="display: inline-block">
					<div class="dp-title-item" style="display: flex;justify-content: space-between;">
						<div  v-for="item in data.basics.headCn" :key="item">{{ item }}</div>
					</div>
					<div class="dp-title-item2" style="display: flex;justify-content: space-between;margin:0 5px">
						<div v-for="item in data.basics.headEn" :key="item">{{ item }}</div>
					</div>
				</div>
			</div>
			<div class="phone_icon">
				<a :href="'tel:' + data.basics.hotline">
					<img src="@/assets/phone.png" />
				</a>
			</div>
			<!-- 时间 -->
			<div class="dp-hq-time" :style="data.tenantId=='MHDD'?'right:40px':''">
						{{ data.time }}
						<span style="margin-left: 20px">开盘</span>
			</div>
		</div>
		<div class="dp-conter">
			<!-- 行情 -->
			<div v-show="data.tabbarHover == 0" class="dp-hq">
				<div class="dp-hq-pmd" :style="{ 'backgroundColor': data.basics.backGroundColor||'rgb(0,0,0)' }">
					
					<marquee class="marqueee" v-if="data.basics.headRollFlag" scrollamount="5" loop="loop" style="color: red">{{ data.basics.headRollDesc }}</marquee>
					<div v-show="!data.basics.headRollFlag" style="color: red; text-align: center; overflow-x: scroll; white-space: nowrap">{{ data.basics.headRollDesc }}</div>
				</div>
				<div class="dp-hq-header">
					<div v-for="(item, index) in data.hqHeader" :key="index">{{ item }}</div>
				</div>

				<div class="dp-hq-conter">
					<div class="dp-hq-item" :style="index % 2 == 0 ? 'background:'+adjustGrayscaleRgb(data.basics.backGroundColor||'rgb(0,0,0)') : ''" v-for="(item, index) in data.hqConter" :key="index">
						<div style="color: #ffbf24">{{ item.productName }}</div>
						<div :class="item.backColor" :style="item.highPrice==item.lowPrice?'color:#fff':item.backPrice > item.yesyBackPrice ? 'color:red' : 'color:#0f0'">{{ item.backPrice || "——" }}</div>
						<div :class="item.saleColor" :style="item.highPrice==item.lowPrice?'color:#fff':item.salePrice > item.yesySalePrice ? 'color:red' : 'color:#0f0'">{{ item.salePrice || "——" }}</div>
						<div class="gd" style=" color: red; font-weight: 500">
							{{ item.highPrice || "——" }}
							<div style="color: #0f0">{{ item.lowPrice || "——" }}</div>
						</div>
					</div>
					<div class="msg" v-show="data.basics" :style="data.tenantId=='MHDD'?'padding-left:40px':''">
						<div v-if="data.basics.disclaimers" style="color: red">免责声明：{{ data.basics.disclaimers }}</div>
						<div v-if="data.basics.hotline">
							热线：
							<a :href="'tel:' + data.basics.hotline">{{ data.basics.hotline }}</a>
						</div>
						<div v-if="data.basics.company">公司：{{ data.basics.company }}</div>
						<div v-for="item in data.basics.addressList" :key="item">地址：{{ item }}</div>

						<div v-if="data.basics.officialWebsite">
							官网：
							<a :href="data.basics.officialWebsite">{{ data.basics.officialWebsite }}</a>
						</div>
						<div v-if="data.basics.recordNumbe">备案号：{{ data.basics.recordNumber }}</div>
						
					</div>
					<div style="text-align: center; color: #999;width:100%; font-size: 12px;position: absolute;bottom: 0;left: 50%;transform: translate(-50%);">
							<a href="http://www.szzwei.cn/">技术支持 深圳市中威智能信息科技有限公司</a>
						</div>
				</div>
			</div>
			<!-- 基差 -->
			<div v-show="data.tabbarHover == 1" class="dp-jc">
				<div class="dp-hq-pmd" :style="{ 'backgroundColor': data.basics.backGroundColor||'rgb(0,0,0)' }">
					<div class="dp-hq-time" :style="data.tenantId=='MHDD'?'right:40px':''">{{ data.time }}开盘</div>
				</div>
				<div class="dp-hq-header">
					<div v-for="(item, index) in data.hqHeader" :key="index">{{ item }}</div>
				</div>
				<div class="dp-hq-conter">
					<div v-for="(item, index) in data.jxConter" :key="index">
						<div style="background: #d7a94b; text-align: center; padding: 5px 0; color: #fff; font-weight: bold; font-size: 18px">{{ item.groupName }}</div>
						<div class="dp-hq-item" :style="indexs % 2 == 0 ? 'background:'+adjustGrayscaleRgb(data.basics.backGroundColor||'rgb(0,0,0)') : ''" v-for="(items, indexs) in item.basisList" :key="indexs">
							<div style="color: #ffbf24">{{ items.productName }}</div>
							<div>{{ items.buyPrice || "——" }}</div>
							<div>{{ items.salePrice || "——" }}</div>
							<div>{{ items.basisValue || "——" }}</div>
						</div>
					</div>
					<div class="msg" :style="data.tenantId=='MHDD'?'padding-left:40px':''">
						<div style="color: red">声明：{{ data.basics.basisStatement }}</div>
					</div>
				</div>
			</div>
			<!-- 资讯 -->
			<div v-show="data.tabbarHover == 2" class="dp-zx">
				<iframe  v-if="data.isMobile" width="100%" height="100%" src="https://www.sge.com.cn/h5_news/sjsgg"></iframe>

				<iframe v-else width="100%" height="100%" src="https://www.sge.com.cn/jjsnotice"></iframe>

			</div>
			<!-- 财经 -->
			<div v-show="data.tabbarHover == 3" class="dp-cj">
				<iframe width="100%" height="100%" src="https://www.fecn.net/finance/"></iframe>
			</div>
		</div>
		<!-- tabbar -->
		<div class="dp-tabbar" :style="{ 'backgroundColor': adjustGrayscaleRgb(data.basics.backGroundColor||'rgb(0,0,0)') }">
			<div v-if="data.basics.marketShowFlag" @click="changeTabbar(0)" :style="data.tabbarHover == 0 ? 'background:'+data.basics.backGroundColor : ''" class="dp-item">行情</div>
			<div v-if="data.basics.basisShowFlag" @click="changeTabbar(1)" :style="data.tabbarHover == 1 ? 'background:'+data.basics.backGroundColor: ''" class="dp-item">基差</div>
			<div v-if="data.basics.newsShowFlag" @click="changeTabbar(2)" :style="data.tabbarHover == 2 ? 'background:'+data.basics.backGroundColor : ''" class="dp-item">资讯</div>
			<div v-if="data.basics.financeShowFlag" @click="changeTabbar(3)" :style="data.tabbarHover == 3 ? 'background:'+data.basics.backGroundColor : ''" class="dp-item">财经</div>
		</div>
	</div>
</template>

<script setup>
// import screenfull from 'screenfull';
import { ref, onMounted, onUnmounted } from "vue";
import api from "@/api/http";
import WaterMarks from "@/components/WaterMarks.vue";
import FloatingWindow from "@/components/FloatingWindow.vue";
import notPage from "@/components/notPage.vue";
import useTimeDifference from "@/composables/useTimeDifference.js";
const { calculateTimeDifference, timeDifference, difference2 } = useTimeDifference();//判断本地时间和服务器时间
const data = ref({
	isMobile:false,
	color: "#000000",
	time: "",
	notPages: false,
	count: 0,
	tabbarHover: 0,
	tabbar: [{ name: "行情" }, { name: "基差" }, { name: "资讯" }, { name: "财经" }],
	hqHeader: ["商 品", "回 购", "销 售", "高 / 低"],
	hqConter: [],
	jxConter: [],
	tenantId: "",
	basics: {}
});
let timerId;
let timerId2;
let timerId3;
const formatDate =(date)=> {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;
}
// 定义增加计数的函数
const updateTime = () => {
	// 获取当前时间
	data.value.time = formatDate(new Date());
	//行情
};
// 定义增加计数的函数
const updateTime2 = () => {
	// 获取行情
	if (difference2.value == "过期") {
		clearInterval(timerId2);
		return;
	}
	let contrastData = data.value.hqConter;
	api.get("/metalMarket/board/listMarket/" + data.value.tenantId).then(response => {
		if (new Date(data.value.basics.usageTime[0]).getTime() > new Date().getTime()) {
			data.value.hqConter = [];
			data.value.jxConter = [];
			clearInterval(timerId2);
			clearInterval(timerId3);
			return;
		}
		if (response.data.length>=1&&response.data[0].productName) {
			data.value.hqConter = response.data; // 处理响应数据
			calculateTimeDifference(new Date(data.value.basics.usageTime[1]).getTime(), Number(response.data[0].currentTime));
			if(contrastData.length&&data.value.basics.isSwitch){
				data.value.hqConter.forEach((item,index)=>{
				//判断回购数据
				// if(item.backPrice > item.yesyBackPrice&&item.backPrice>contrastData[index].backPrice){//判断涨//并且比上一次高
				// 	item.backColor='red';
				// }
				// if(item.backPrice < item.yesyBackPrice&&item.backPrice<contrastData[index].backPrice){//判断涨//并且比上一次高
				// 	item.backColor='green'
				// }
				// //判断销售数据
				// if(item.salePrice > item.yesySalePrice&&item.salePrice>contrastData[index].salePrice){//判断涨//并且比上一次高
				// 	item.saleColor='red'
				// }
				// if(item.salePrice < item.yesySalePrice&&item.salePrice<contrastData[index].salePrice){//判断涨//并且比上一次高
				// 	item.saleColor='green'
				// }
				if(item.backPrice>contrastData[index].backPrice){//判断涨//并且比上一次高
					item.backColor='red';
				}
				if(item.backPrice<contrastData[index].backPrice){//判断涨//并且比上一次高
					item.backColor='green'
				}
				//判断销售数据
				if(item.salePrice>contrastData[index].salePrice){//判断涨//并且比上一次高
					item.saleColor='red'
				}
				if(item.salePrice<contrastData[index].salePrice){//判断涨//并且比上一次高
					item.saleColor='green'
				}
				
			})
			}
			
		}
	});
};
const updateTime3 = () => {
	//基础数据
	api.get("/metalMarket/sysTenantBoardConf/getByWebUrl/" + data.value.tenantId).then(response => {
		if (response.data) {
			updateTime2();
			if(data.value.basics){
				if(data.value.basics.scaleFactor != response.data.scaleFactor){
					document.body.style.zoom=response.data.scaleFactor
					// document.html.style.transform = 'scale('+response.data.scaleFactor+')';
				}
			}else{
				document.body.style.zoom=response.data.scaleFactor
				// document.html.style.transform = 'scale('+response.data.scaleFactor+')';

			}
			data.value.basics = response.data; // 处理响应数据
			
			if (!data.value.basics.usageTime) {
				difference2.value = "过期";
				clearInterval(timerId2);
				clearInterval(timerId3);
			}
		} else {
			clearInterval(timerId3);
		}
	});
};
//切换tabbar
const changeTabbar = index => {
	data.value.tabbarHover = index;
};
//计算灰度值
function adjustGrayscaleRgb(rgb, factor = 0.1) {
	if (!rgb || typeof rgb !== 'string') {
    throw new Error('Invalid RGB value');
  }

  // 解析 RGB 值
  const rgbArray = rgb.match(/\d+/g);

  if (!rgbArray || rgbArray.length !== 3) {
    throw new Error('Invalid RGB format');
  }

  let [r, g, b] = rgbArray.map(Number);

  // 混合白色
  r = Math.round(r + (255 - r) * factor);
  g = Math.round(g + (255 - g) * factor);
  b = Math.round(b + (255 - b) * factor);

  // 返回调整后的 RGB 字符串
  return `rgb(${r}, ${g}, ${b})`;
}
function loadWeixinScript() {//加载微信sdk
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.src = "https://res.wx.qq.com/open/js/jweixin-1.4.0.js";
		script.onload = () => resolve();
		script.onerror = () => reject(new Error("Weixin script load error"));
		document.head.appendChild(script);
	});
}
const initializeWeixinSDK = (config) => {
	const wx = window.wx; // 确保 wx 是从 window 对象中获取
	const wxConfig = {
		debug: false,
		appId: config.appId,
		timestamp: config.timestamp,
		nonceStr: config.nonceStr,
		signature: config.signature,
		jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"]
	};
	wx.config(wxConfig);
	wx.ready(() => {
		const timestamp = new Date().getTime(); // 获取当前时间戳
		const imageUrlWithTimestamp = `${data.value.basics.shareLogoUrl}?t=${timestamp}`;
		// 微信SDK初始化成功后的回调
		wx.onMenuShareTimeline({//朋友圈
			title: data.value.basics.logoDesc,
			link: window.location.href,
			imgUrl: imageUrlWithTimestamp,
			success: function () {
				// 用户确认分享后执行的回调函数
			},
			cancel: function () {
				// 用户取消分享后执行的回调函数
			}
		});

		wx.onMenuShareAppMessage({//pengyop
			title: data.value.basics.logoDesc,
			desc: data.value.basics.shareDesc,
			link: window.location.href,
			imgUrl: imageUrlWithTimestamp,
			success: function () {
				console.log()
				// 用户确认分享后执行的回调函数
			},
			cancel: function (err) {
				// 用户取消分享后执行的回调函数
			}
		});
	});

	wx.error(function (res) {
		console.error("Weixin config error:", res);
	});
};
async function getWeixinConfig(url) {
  try {
	const response = await api.get('/metalMarket/sysTenantBoardConf/getWxJsConfig?url=' + url);
	return response.data;
  } catch (error) {
    console.error('Error fetching Weixin config:', error);
    throw error;
  }
}

onMounted(async () => {
	// window.location.href = '/webSocketPage';
	// this.$router.push({ name: 'webSocketPage' });
	await loadWeixinScript();
	// const url = window.location.href.split("#")[0]; // 当前页面的URL
	const url = window.location.href; // 获取当前页面的完整 URL
	const config = await getWeixinConfig(url);
	initializeWeixinSDK(config);

	
	const urlParams = new URLSearchParams(window.location.search);
	data.value.tenantId = urlParams.get("v");
	updateTime3();
	timerId = setInterval(updateTime, 500);
	timerId2 = setInterval(updateTime2, 1000);
	timerId3 = setInterval(updateTime3, 120000);

	// //基差
	api.get("/metalMarket/board/listBasis/" + data.value.tenantId).then(response => {
		data.value.jxConter = response.data; // 处理响应数据
	});
});
//基础信息

onUnmounted(() => {
	
	clearInterval(timerId);
	clearInterval(timerId2);

});
</script>
<script>
export default {
	name: "homeDP"
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.red{background: red;color: #fff !important;}
.green{background: green;color:#fff !important}

html{background:rgb(42, 52, 58);}
.gd{font-size: 20px;line-height: 24px !important;padding-top:14px;font-weight: bold; }
.phone_icon {
	display: none;
}
.dp {
	position: relative;
	overflow: hidden;
	/* background: #000000; */
	color: #fff;
	width: 100%;
	height: 100vh;
}

.dp-conter {
	padding-bottom: 40px;
	padding-top: 100px;
}

.dp-hq-header,
.dp-hq-item {
	display: flex;
	align-items: center;
	justify-content: space-around;
	text-align: center;

	height: 70px;
	font-weight: bold;
	font-size: 24px;
}

.dp-hq-item {
	white-space: nowrap;
	font-size: 28px;
	/* height: 70px; */
}

.dp-hq-item > div {
	width: 100%;
	height: 70px;line-height: 70px;
}

.dp-hq-pmd {
	font-size: 16px;
	height: 10px;
	padding-right: 20px;
	background: #191c21;
	scrollbar-width: none;
}

.dp-hq-time {
	position: absolute;right:10px;top:45px;
	text-align: right;
	color: red;
	font-size: 24px;
}

.dp-hq-conter {
	border-top: 1px solid #fff;
	/* background: red; */
	overflow-y: scroll;
	height: calc(100vh - 250px);
	scrollbar-width: none;
	position: relative;
	/* Firefox */
}

.dp-hq-pmd > div::-webkit-scrollbar,
.dp-hq-conter::-webkit-scrollbar {
	display: none;
	/* Safari 和 Chrome */
}

.dp-cj,
.dp-jc,
.dp-zx {
	height: calc(100vh - 160px);
}

.dp-title {
	
	position: fixed;
	display: flex;
	justify-content: space-between;
	top: 0;
	width: 100%;
	background: #000000;
	height: 100px;
	line-height: 80px;
	z-index: 1;
}
.dp-title-item{
	line-height: 70px;
	font-weight: bold;
	font-size: 50px;
}
.dp-title-item>div{
	margin:0 5px;
}
.dp-title-item2{
	line-height: 20px;
	font-size: 30px;margin-top:20px;
}
.dp-title-item,.dp-title-item2{
  
  text-align: center;
}
.dp-tabbar {
	display: flex;
	align-items: center;
	justify-content: space-around;
	text-align: center;
	position: fixed;
	bottom: 0;
	width: 100%;
	background: #191c21;
	font-size: 18px;
	border-top: 1px solid #fff;
}

.dp-item {
	cursor: pointer;
	width: 100%;
	height: 60px;
	line-height: 60px;
	font-weight: bold;
}
.msg > div {
	font-size: 14px;
	margin-top: 10px;
	padding-left: 10px;
}
.dqtx {
	width: 100vw;
	height: 100vh;
	background: #fff;
	position: fixed;
	z-index: 99999999;
	color: red;
	text-align: center;
	padding: 20px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
}
.dqtx p {
	margin-top: 20px;
}
/* .tabbar-hover {
	background: #000000;
} */
@media only screen and (max-width: 767px) {
	.dp-hq-pmd{height:30px;padding:10px 0;}
	body{zoom:1 !important}
	.gd{font-size: 16px;line-height: 20px !important;margin-top: 0;}
	.phone_icon {
		display: block;
		float: right;
		padding: 8px;
		width: 50px;
	}
	.phone_icon img {
		width: 100%;
		height: auto;
	}
	.dp-hq-item {
		font-weight: bold;
		font-size: 20px;
		height: auto;
		border-bottom: 1px solid #292a2c;
	}
	.dp-hq-item > div {
		height: 55px;
		line-height: 55px;
		border-right: 1px solid #333538;
	}
	.dp-hq-item > div:last-child {
		padding-top: 10px;
		border-right: none;
	}

	.dp-tabbar {
		background: #fff;
	}
	.dp-item {
		font-size: 16px;
		font-weight: 400;
	}
	/* .tabbar-hover {
		background: #f5f5f5;
	} */
	.dp-hq-header {
		font-weight: bold;
		font-size: 20px;
		height: 50px;
	}
	.dp-hq-time {
		top:50px;
		font-size: 16px;
	}
	.dp-conter {
		padding-top: 70px;
	}
	.dp-hq-conter {
		height: calc(100vh - 220px);
	}
	.dp-title {
		height: 70px;
	}
	.dp-title-item{
	font-size: 35px;
	line-height: 50px;
}
.dp-title-item2{
	font-size: 20px;
	line-height: 10px;
}
.dp-title-item>div{
	margin:0px;
}
.marqueee{margin-top:14px}
}
</style>
